import React from 'react';
import styled from 'styled-components';

import { PriceProps } from '../../pages/price';
import { color, mediaQuery } from '../../styles/variables';
import AvailableClinic from './AvailableClinic';

const TAX_RATE = 1.1;
const addComma = (number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// 税抜価格を返す ex: 12000 -> 12,000円
const getTaxExcludedPrice = (price: number) => {
  // priceが設定されてない場合、何もないことを示すマークを表示
  if (price === 0) {
    // 全角ダッシュ（U+2014）
    return `—`;
  }
  return `${addComma(price)}円`;
};

// 税込価格を返す ex: 12000 -> （税込13,200円）
const getTaxIncludedPrice = (price: number) => {
  // priceが設定されてない場合、何もないことを示すマークを表示
  if (price === 0) {
    // 全角ダッシュ（U+2014）
    return `—`;
  }
  if (price === null) {
    // 空白
    return ``;
  }
  return `${addComma(Math.floor(price * TAX_RATE))}円`;
};

export const Table: React.VFC<PriceProps> = ({ contentId, title, priceOption, treatmentHead, content, annotation }) => {
  return (
    <Container id={`priceTable-${contentId}`} className={`${title ? 'has-title' : ''}`}>
      {title && (
        <TitleContainer>
          <h3>{title}</h3>
        </TitleContainer>
      )}
      <TableContainer>
        <TreatmentTable>
          <thead>
            <tr>
              {
                treatmentHead && (
                  <TreatmentHead>
                    <p>{treatmentHead}</p>
                  </TreatmentHead>
                )
              }
              {
                !treatmentHead && (
                  <TreatmentHeadBlank>施術名</TreatmentHeadBlank>
                )
              }
              <PriceHead>
                <p>価格</p>
                {priceOption && (
                  <PriceHeadOption>
                    {priceOption.map((option, i) => (
                      <li key={`Option-${contentId}-${i}`}>{option}</li>
                    ))}
                  </PriceHeadOption>
                )}
              </PriceHead>
              <AvailableClinicHead>対象院</AvailableClinicHead>
            </tr>
          </thead>

          <tbody>
            {content.map((item, i) => (
              <tr key={`TableContent-${contentId}-${i}`}>
                <td>
                  <TreatmentOption>
                    <TreatmentOptionWrapper>
                      <TreatmentOptionContainer>
                        <ContentHead>{item.head}</ContentHead>
                        <Subhead>{item.subhead}</Subhead>
                      </TreatmentOptionContainer>
                    </TreatmentOptionWrapper>
                    {item.option[0].head && (
                      <ul>
                        {item.option.map((option, j) => (
                          <li key={`Subhead-${contentId}-${i}-${j}`}>
                            <div>
                              <OptionHead>{option.head}</OptionHead>
                              <Subhead>{option.subhead}</Subhead>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </TreatmentOption>
                </td>
                <td>
                  <PriceList>
                    {item.option.map((option, j) => {
                      if (option.price) {
                        return (
                          <PriceListItem key={`PriceList-${contentId}-${j}`}>
                            <Price>
                              {option.price.map((price, k) => (
                                <li key={`Price-${contentId}-${i}-${j}-${k}`}>
                                  <div>
                                    <TaxIncludedPrice>
                                      {getTaxIncludedPrice(price)}
                                      {!!price && option.floor && <span>~</span>}
                                      {!!price && option.unit && <span>{option.unit}</span>}
                                    </TaxIncludedPrice>
                                  </div>
                                </li>
                              ))}
                            </Price>
                          </PriceListItem>
                        );
                      }
                      if (option.priceOption) {
                        return (
                          <li key={`PriceList-${contentId}-${j}`}>
                            <Price>
                              {option.priceOption.map((priceOption, k) => (
                                <PriceOption key={`Price-${contentId}-${i}-${j}-${k}`}>{priceOption}</PriceOption>
                              ))}
                            </Price>
                          </li>
                        );
                      }
                    })}
                  </PriceList>
                </td>
                <td>
                  {item.available && (
                    <AvailableList>
                      {item.option.map((_, j) => (
                        <li key={`AvailableClinic-${contentId}-${i}-${j}`}>
                          <AvailableClinic {...item.available} />
                        </li>
                      ))}
                    </AvailableList>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </TreatmentTable>
      </TableContainer>
      {annotation && (
        <Annotation>
          {annotation.map((item, i) => (
            <p key={`TableAnnotation-${contentId}-${i}`}>{item}</p>
          ))}
        </Annotation>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-inline: auto;
  width: 100%;
  max-width: 65rem;
  margin-top: 18.75rem;
  + [class*="Table__Container"] {
    margin-top: 9.375rem;
  }
  &:not(.has-title) {
    margin-top: 0;
  }
`;

const TitleContainer = styled.div`
  padding: 0 1rem;

  > h3 {
    width: 100%;
    font-size: 1.4rem;
    padding: 0.8rem;
    font-weight: 400;
    background-color: ${color.pink};
    text-align: center;
    margin: 0;

    @media screen and ${mediaQuery.mobile} {
      font-size: 1rem;
    }
  }
`;

const TableContainer = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 0 0 1rem;

  @media screen and ${mediaQuery.tablet} {
    padding: 0 1rem;
  }
`;

const TreatmentTable = styled.table`
  width: 100%;
  min-width: 42rem;
  table-layout: fixed;
  height: 1px; // tdの子要素で高さを継承させるため、文字サイズ以下の任意の数字が入っていればいい
  text-align: center;
  margin: 2rem 0 0;
  border-spacing: 0;
  border-collapse: collapse;

  > thead {
    th {
      border-bottom: 1px solid ${color.grey[200]};
      font-size: 0.9rem;
      padding: 0;
    }

    th + th {
      border-left: 1px solid ${color.grey[200]};
    }
  }

  > tbody {
    tr {
      border-bottom: 1px solid ${color.grey[200]};
    }

    td {
      padding: 0;
      height: 100%;
    }

    td + td {
      border-left: 1px solid ${color.grey[200]};
    }
  }
`;

const TreatmentHeadBlank = styled.th`
  width: 22rem;
  text-indent: -9999px;
  @media screen and ${mediaQuery.mobile} {
    width: 18rem;
  }
`;

const TreatmentHead = styled.th`
  width: 22rem;
  > p {
    font-weight: normal;
    font-size: 1.1rem;
  }
  @media screen and ${mediaQuery.mobile} {
    width: 18rem;
  }
`;

const PriceHead = styled.th`
  > p {
    padding: 0.5rem 0;
  }
`;

const PriceHeadOption = styled.ul`
  display: flex;
  border-top: 1px solid ${color.grey[200]};
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;

  > li {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    justify-content: center;
  }

  > li + li {
    border-left: 1px solid ${color.grey[200]};
  }
`;

const AvailableClinicHead = styled.th`
  width: 5.6rem;
`;

const TreatmentOption = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  line-height: 1.3;

  > ul {
    list-style: none;
    flex: 1;
    margin: 0;
    padding: 0;
    border-left: 1px solid ${color.grey[200]};

    > li {
      height: 7rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > li + li {
      border-top: 1px solid ${color.grey[200]};
    }
  }
`;

const TreatmentOptionWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
`;

const TreatmentOptionContainer = styled.div`
  width: 100%;
`;

const ContentHead = styled.p`
  margin: 0;
  font-size: 1rem;
`;

const OptionHead = styled.p`
  margin: 0;
  font-size: 0.9rem;
`;

const Subhead = styled.p`
  margin: 0;
  font-size: 0.75rem;
`;

const PriceList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: 100%;
  list-style: none;

  > li {
    flex: 1;
  }

  > li + li {
    border-top: 1px solid ${color.grey[200]};
  }
`;

const PriceListItem = styled.li`
  height: 7rem;
`;

const PriceOption = styled.li`
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
`;

const Price = styled.ul`
  display: flex;
  list-style: none;
  height: 100%;
  margin: 0;
  padding: 0;

  > li {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > li + li {
    border-left: 1px solid ${color.grey[200]};
  }
`;

const TaxExcludedPrice = styled.p`
  margin: 0;
  font-size: 1rem;
`;

const TaxIncludedPrice = styled.p`
  margin: 0;
  font-size: 1.2rem;
  @media screen and ${mediaQuery.mobile} {
    font-size: 1.1rem;
  }
`;

const AvailableList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > li + li {
    border-top: 1px solid ${color.grey[200]};
  }
`;

const Annotation = styled.div`
  font-size: 0.75rem;
  margin: 1.5rem 0 0;
  padding: 0 1rem;
`;

export default Table;
