import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import FloatingButton from '../components/common/FloatingButton';
import { Meta } from '../components/common/Meta';
import PageHeading from '../components/common/PageHeading';
import { PriceTextSVG } from '../components/common/TextSVG';
import BasicPrice from '../components/price/BasicPrice';
import CosmeticsTable from '../components/price/CosmeticsTable';
import IndexList from '../components/price/IndexList';
import Table from '../components/price/Table';
import WhiteningTable from '../components/price/WhiteningTable';
import { color, mediaQuery } from '../styles/variables';

export type PriceProps = {
  title: string;
  contentId: string;
  annotation: string[];
  priceOption: string[];
  treatmentHead: string;
  content: {
    head: string;
    subhead: string;
    option: {
      head: string;
      subhead: string;
      price: number[];
      priceOption: string[];
      floor: boolean;
      unit: string;
    }[];
    available: {
      yotsuya: boolean;
      shimbashi: boolean;
      shinsaibashi: boolean;
      tamachi: boolean;
      ikebukuro: boolean;
    };
  }[];
};

const PricePage = () => {
  const data = useStaticQuery(graphql`
    query Price {
      allPriceContentYaml(sort: { order: ASC, fields: contentId }) {
        nodes {
          contentId
          title
          annotation
          priceOption
          treatmentHead
          content {
            head
            subhead
            available {
              shimbashi
              yotsuya
              shinsaibashi
              tamachi
              ikebukuro
            }
            option {
              head
              subhead
              price
              priceOption
              floor
              unit
            }
          }
        }
      }
    }
  `);

  const idOrder = [1, 2, 3, 4, 5, 6, 32, 26, 24, 25, 7, 9, 10, 331, 332, 333, 28, 29, 31, 11, 12, 14, 30, 15, 16, 17, 18, 19, 21, 22];
  const prices: PriceProps[] = [];
  idOrder.map((o) =>
    data.allPriceContentYaml.nodes.map((p: PriceProps) => {
      if (Number(p.contentId) === o) prices.push(p);
    })
  );

  return (
    <Container>
      <Meta title={'料金一覧'} />
      <PageHeading>
        <PriceTextSVG />
      </PageHeading>
      <Description>自由診療も明確にお伝えします。必要な処置を、適正な負担で行います。</Description>
      <SubHeading>施術別で探す</SubHeading>
      <IndexList />
      <TaxInclude>※全て税込価格</TaxInclude>
      <BasicPrice />
      {prices.map((content: PriceProps) => (
        <Table {...content} key={`Table-${content.contentId}`} />
      ))}
      <WhiteningTable />
      <CosmeticsTable />
      <FloatingButton visible={true} />
    </Container>
  );
};

const Container = styled.main`
  width: 100%;
  margin: 0 auto;
  padding: 8rem 0 10rem;
`;

const Description = styled.p`
  font-size: 1rem;
  text-align: center;
  padding: 0 2rem;
  margin: 2rem 0 0;
`;

const SubHeading = styled.h2`
  background: ${color.pink};
  padding: 1rem 0;
  text-align: center;
  margin: 4rem 0 0;
  letter-spacing: 0.2em;
  font-size: 1.6rem;
  font-weight: 400;

  @media screen and ${mediaQuery.mobile} {
    font-size: 1.2rem;
  }
`;

const TaxInclude = styled.p`
  font-size: 1rem;
  text-align: right;
  padding: 0 1rem;
  width: 100%;
  max-width: 65rem;
  margin-top: 7.375rem;
  margin-inline: auto;
`;

export default PricePage;
