import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import styled from 'styled-components';

import { color, mediaQuery } from '../../styles/variables';

const IndexList = () => {
  return (
    <Container>
      <li>
        <AnchorLink to={'/price#priceTable-basic'} stripHash>
          カウンセリング料
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-1'} stripHash>
          ピーリング
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-2'} stripHash>
          イオン導入
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-3'} stripHash>
          レーザー治療
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-6'} stripHash>
          HIFU
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-26'} stripHash>
          ジェネシス (エクセルV+)
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-24'} stripHash>
          ピコレーザー
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-7'} stripHash>
          フォトフェイシャル
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-9'} stripHash>
          ダーマペン
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-10'} stripHash>
          注入治療
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-331'} stripHash>
          AGAメソセラピー
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-29'} stripHash>
          ニキビ根治療法
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-15'} stripHash>
          注射・点滴
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-19'} stripHash>
          肥満症治療
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-21'} stripHash>
          ホームケア
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to={'/price#priceTable-101'} stripHash>
          医療機関専売コスメ
        </AnchorLink>
      </li>
    </Container>
  );
};

const Container = styled.ul`
  margin: 3rem auto 0;
  padding: 0 1rem;
  width: 100%;
  max-width: 65rem;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  font-size: 1rem;

  > li {
    text-align: center;
    > a {
      padding: 1rem;
      display: block;
      text-decoration: none;
      color: ${color.grey[900]};
      background-color: ${color.grey[100]};
      &:hover {
        background-color: ${color.pink};
      }
    }
  }

  @media screen and ${mediaQuery.mobile} {
    grid-template-columns: 1fr 1fr;
    font-size: 0.8rem;
  }
`;

export default IndexList;
